<template>
  <div
    class="container"
  >
    <div class="column-result">
      <div class="container container-small padding-top-null">
        <h1>Wat zijn auteursrechten ook alweer?</h1>
        <p>
          Een ieder die iets unieks en oorspronkelijks creëert, verkrijgt auteursrechten op ‘het werk’ dat is ontstaan. Het eigendom van het fysieke werk kan vervolgens worden overgedragen of doorverkocht, aan een consument, museum of archief, met of zonder de auteursrechten, die  bij de (geestelijk) schepper van het werk kunnen blijven. In beginsel is de maker dus de auteursrechthebbende.
        </p>
        <p>
          Auteursrechten omvatten het alleenrecht om te mogen bepalen of het werk openbaar gemaakt mag worden, bijvoorbeeld publicatie in een tijdschrift of op een website. En of het werk verveelvoudigd mag worden, bijvoorbeeld door posters van een foto te maken, of door een film uit te brengen op dvd. Om een werk op die manier te kunnen gebruiken moeten er afspraken met de rechthebbende(n) worden gemaakt. De toestemming die de rechthebbende aan derden geeft voor dit gebruik heet een licentie, daarin staan ook vaak afspraken over een vergoeding en over de omvang van het gebruik.
        </p>
        <p>
          Wie de auteursrechthebbende op een werk is, verschilt per type werk en kan door de loop der tijd ook veranderen. Auteursrechten vervallen in principe pas na 70 jaar na de dood van de maker. De kans is aanwezig dat de auteursrechten van de maker ondertussen bij erfgenamen liggen. Anderszins kunnen auteursrechten ook zijn overgedragen aan een stichting of andere organisatie. Films vormen hierop sinds 1985 een uitzondering, daarvoor geldt een vermoeden van overdracht aan de producent door alle individuele makers, met uitzondering van de filmmuziek.
        </p>
        <p>
          De Auteurswet beschermt de rechthebbende van een werk, maar daarin staan ook een aantal uitzonderingen opgenomen om tegemoet te komen aan het algemeen belang. Zo behoeven er geen afspraken gemaakt te worden met de rechthebbende voor gebruik van een citaat in een krant wanneer er een recensie over het werk wordt geschreven.
        </p>
        <div class="buttons">
          <router-link to="/" tag="button" class="btn">
            Terug naar de homepagina
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    metaInfo() {
      return {
        title: 'Wat zijn auteursrechten ook alweer?'
      };
    }
  };
</script>

